export const BASE_SERVER = process.env.BACK_URL;
export const CATALOG_SERVER = process.env.CATALOG_URL;

export const BASE_URL = `${BASE_SERVER}/api/`;
export const REGISTER = 'auth/register/';
export const LOGIN = 'auth/login/';
export const LOGOUT = 'auth/logout/';
export const VALIDATE_REGISTER = 'auth/validate_register/';
export const FORGET_PASSWORD = 'auth/password_reset/';
export const RESET_PASSWORD = 'auth/password_reset_confirm/';
export const GOOGLE_OAUTH = 'rest-auth/google/';
export const LINKEDIN_OAUTH = 'rest-auth/linkedin/';
export const USERS = 'users/';
export const USER = 'users/%s/';
export const COMPANY = 'companies/%s/';
export const TEAM = 'team_attributes/';
export const REPORTS = 'reports/';
export const CHANGE_PASSWORD = 'auth/password_change/';
export const REQUESTS = 'requests/';
export const SHARED_REQUESTS = 'requests/shared_requests/';
export const REQUEST_ACCESS = 'requests/%s/request_access/';
export const DRAFTS = 'requests/drafts/';
export const QUEUES = 'requests/queues/';
export const SEARCH = 'requests/search/';
export const REQUEST = 'requests/%s/';
export const SHARE_TAKEOFF_ACCESS = 'requests/%s/share_access/';
export const REQUEST_EDIT_START = 'requests/%s/editing_start/';
export const REQUEST_EDIT_END = 'requests/%s/editing_end/';
export const TRANSFER_TAKEOFF = 'requests/%s/transfer_request/';
export const DUPLICATE_TAKEOFF = 'requests/%s/duplicate_request/';
export const RESUBMIT_INITIAL_INFO = 'requests/%s/resubmit_image_info/';
export const RESUBMIT_TAKEOFF = 'requests/%s/resubmit/';
export const RESUBMIT_TAKEOFF_COST = 'requests/%s/resubmit_cost/';
export const GENERATE_MEASUREMENTS = 'requests/%s/generate/';
export const GENERATE_SAMPLE_REQUEST = 'requests/%s/generate_sample/';
export const GENERATE_ETA = 'requests/%s/eta/';
export const REQUEST_OPTIONAL_FEATURES = 'requests/%s/get_optional_features/';
export const SAVE_REQUEST_OPTIONAL_FEATURES = 'requests/%s/update_optional_features/';
export const DELETE_OUTPUT = 'requests/%s/outputs/%s/';
export const STYLE = 'requests/%s/outputs/%s/style/';
export const ZONE_STYLE = 'requests/%s/zonal_style/';
export const AOI_STYLE = 'requests/%s/aoi_style/';
export const CALCULATE_AREA = 'get_area/';
export const NOTIFICATIONS_URL = '%s/';
export const NOTIFICATION_URL = '%s/%s/';
export const EDIT_OUTPUTS = 'requests/%s/edit_output/';
export const DRAFT_EDIT = 'requests/%s/draft_edit/';
export const EXPORT = 'requests/%s/export/';
export const BULK_EXPORT = 'requests/bulk_export/';
export const EDIT_PARCEL = 'requests/%s/edit_aoi/';
export const PROPERTY_EXPORT_FEATURES = 'requests/%s/export_features/';
export const PLANS = 'plans/';
export const ORDER_SUMMARY = 'plans/%s/order_summary/';
export const BILLING_DETAILS = 'billing_details/';
export const BILLING_DETAIL = 'billing_details/%s/';
export const INVITE_USER = 'auth/invite_user/';
export const SUBSCRIBE_PLAN = 'plans/%s/subscribe/';
export const REMOVE_TEAM_MEMBER = 'auth/remove_member/';
export const TRANSACTION_HISTORY = 'transaction_history/';
export const ENTERPRISE_INQUIRY = 'plans/enterprise_inquiry/';
export const UNSUBSCRIBE = 'plans/unsubscribe/';
export const DOWNLOAD_INVOICE = 'transaction_history/%s/invoice/';
export const UPDATE_CARD = 'payment/update_card/';
export const REMOVE_CARD = 'payment/remove_card/';
export const UPDATE_CARD_CONFIRMATION = 'payment/update_details/';
export const FEATURES = 'features/';
export const CUSTOM_REPORT = 'reports/custom/';
export const RESET_OUTPUTS = 'requests/%s/reset/';
export const RESET_SESSION_OUTPUTS = 'requests/%s/reset_session/';
export const GIC_IMAGERY = 'gic/imagery/';
export const GIC_IMAGERY_UPDATE = 'gic/imagery_update/';
export const TEAM_REQUESTS = 'team_request/';
export const QUEUE_REORDER = 'queue/reorder/';
export const QUEUE_REMOVE = 'queue/remove/';
export const REMOVE_REQUESTS = 'requests/remove_requests/';
export const BLUEPRINT_REMOVE_REQUESTS = 'construction/requests/remove_requests/';
export const REQUEST_SHARE_LINK = 'requests/%s/share/';
export const OUTPUT = 'requests/%s/output/';
export const SHARED_OUTPUTS = 'shared_request/%s/output/';
export const BLUEPRINT_SHARED_OUTPUTS = 'construction_shared_request/%s/output/';
export const BLUEPRINT_TAKE_OFF_OUTPUT = 'construction/requests/%s/takeoff_output/';
export const BLUEPRINT_TAKEOFF_STYLE = 'construction/requests/%s/takeoff_settings/';
export const BLUEPRINT_SHARED_TAKEOFF_OUTPUT = 'construction_shared_request/%s/takeoff_output/';
export const SWITCH_PLAN = 'plans/switch_plan/';
export const STRIPE_CLIENT_SECRET = 'payment/card_setup_intent/';
export const NEARBY_PARCELS = 'requests/%s/nearby_parcel/';
export const CUSTOM_FEATURES = 'features/custom/';
export const CUSTOM_FEATURE = 'features/custom/%s/';
export const AERIAL_REQUESTS = 'folders/';
export const EXPORT_FOLDER = 'folders/folder_export/';
export const BLUEPRINT_SHARED_REQUEST = 'construction_shared_request/%s/info/';
export const ACTIVE_SESSION_REQUEST = 'requests/%s/active/';
export const ZONES = 'requests/%s/zones/';
export const REXEL_BACK_URL = `${BASE_URL}rexel/login/`;
export const ZONAL_OUTPUTS = 'requests/%s/zonal_output/';
export const ZONAL_OUTPUTS_SHARED = 'shared_request/%s/zonal_output/';
export const CREATE_GROUP = 'requests/%s/groups/';
export const EDIT_GROUP = 'requests/%s/groups/%s/';
export const MOVE_GROUP = 'requests/%s/groups/%s/move/';
export const GROUP_FEATURES_TAKEOFF = 'features/groups/';
export const EDIT_GROUP_FEATURES_TAKEOFF = 'features/groups/%s/';
export const SUPPLEMENTARY_FILES = 'construction/requests/%s/supplementary_file/';
export const MEASUREMENT_VIEW_TYPE = 'construction/requests/%s/';

// Bulk APIS
export const BULK_DUPLICATE = 'requests/bulk_duplicate/';
export const BULK_SHARE = 'requests/bulk_share/';

/**
 * Takeoff Settings
 */
export const FEATURES_EXPORTS = 'features/exports/';
export const ADMIN_SETTINGS = 'users/admin_settings/';
export const TAKEOFF_FEATURES = 'features/settings/';
export const TAKEOFF_GEOJSON = 'features/settings/%s/feature_data/';
export const EDIT_TAKEOFF_FEATURE = 'features/settings/%s/';
export const FEATURES_ORDER = 'features/order/';

/**
 * Takeoff Notes
 */
export const REQUEST_NOTES = 'requests/%s/notes/';
export const REQUEST_NOTE = 'requests/%s/notes/%s/';
export const REQUEST_NOTE_REPLY = 'requests/%s/notes/%s/reply/';
export const REQUEST_NOTE_RESOLVE = 'requests/%s/notes/%s/resolve/';
export const EXPORT_NOTES = 'requests/%s/notes/export/';
export const SHARED_REQUEST_NOTES = 'shared_request/%s/notes/';
export const SHARED_REQUEST_NOTE = 'shared_request/%s/notes/%s/';
export const SHARED_REQUEST_NOTE_REPLY = 'shared_request/%s/notes/%s/reply/';
export const BLUEPRINT_SHARED_REQUEST_NOTES = 'construction_shared_request/%s/notes/';
export const BLUEPRINT_SHARED_REQUEST_NOTE = 'construction_shared_request/%s/notes/%s/';

/**
 * Auto measure
 */

export const USAGE_SUMMARY = 'users/usage_summary/';
export const BLUEPRINT_USAGE_SUMMARY = 'construction/requests/usage_summary/';

/**
 * Config
 */
export const COMPANY_CONFIG = 'auth/enterprise_config/';

// Icon tool
export const ICONS = 'icon/';
export const ICON_DELETE = 'icon/%s/';

// Arrow tool
export const UPDATE_ARROWS = 'requests/%s/arrows/%s/';

// Hubspot Forms
export const HUBSPOT_FORMS = 'https://api.hsforms.com/submissions/v3/integration/submit/%s/%s/';
export const US_LAWNS_HUBSPOT = 'https://meetings.hubspot.com/shefali-from-attentive';

// US Stripe Migration
export const US_STRIPE_MIGRATION_FAQ =
    'https://www.notion.so/attentive-support/Frequently-Asked-Questions-around-Credit-Card-Migration-3bda8f11ab01434787691eac2768ca18';

// Optional Features
export const OPTIONAL_FEATURES = 'reports/%s/optional_features/ ';
export const SAVE_OPTIONAL_FEATURES = 'reports/%s/optional_features/';
export const DELETE_OPTIONAL_FEATURE = 'reports/%s/optional_features/%s/';

// Referral
export const REFERRAL = 'referral/';

// Ticketing System
export const TICKETS = 'tickets/';

// FAQs
export const FAQS_LINK = 'https://go.attentive.ai/faqs';

// Help-center
export const HELP_CENTER = 'https://attentive.ai/help-center';

// Product Manual
export const DEFAULT_MANUAL_LINK = 'https://go.attentive.ai/product-manual';

// Blueprint Takeoff
export const UPDATE_BLUEPRINT_REQUEST = 'construction/requests/%s/update_requirements/';
export const GENERATE_BLUEPRINT_REQUEST = 'construction/requests/%s/generate/';
export const GENERATE_BLUEPRINT_RESUBMIT_REQUEST = 'construction/requests/%s/resubmit/';
export const DELETE_BLUEPRINT_DRAFT = 'construction/requests/%s/delete/';
export const BPT_MANUAL_COST = 'construction/requests/%s/bpt_manual_cost/';
export const BPT_WORKABLE_SHEETS = 'construction/requests/%s/sheets/';
export const BPT_UPDATE_SCALE = 'construction/requests/%s/update_scale/';
export const BPT_SCALE_LINE_UPDATE = 'construction/requests/%s/scale_tool/';
export const BPT_CATEGORIES = 'construction/requests/categories/';

// Blueprint Custom Feature
export const ADD_BLUEPRINT_CUSTOM_FEATURE = 'construction/requests/%s/add_custom_feature/';
export const ADD_BLUEPRINT_FEATURE_TAGS = 'construction/requests/%s/feature_tags/';
export const EDIT_BLUEPRINT_CUSTOM_FEATURE = 'construction/requests/%s/edit_custom_feature/';
export const DELETE_BLUEPRINT_CUSTOM_FEATURE = 'construction/requests/%s/delete_custom_feature/';
export const ALL_BLUEPRINT_FEATURES = 'construction/requests/%s/features/';
export const BPT_FORMULAS = 'construction/requests/%s/feature_attribute_formulas/';
export const BPT_SHARED_VIEW_FORMULAS = 'construction_shared_request/%s/feature_attribute_formulas/';
export const BPT_ATTRIBUTES = 'construction/requests/feature_attributes/';
export const BPT_SHEET_ATTRIBUTES = 'construction/requests/%s/sheet_attribute/';
export const SHARED_BPT_SHEET_ATTRIBUTES = 'construction_shared_request/%s/sheet_attribute/';
// construction_shared_request/%s/sheet_attribute/

// Cancel Takeoff
export const CANCEL_TAKEOFF = 'requests/%s/cancel/';

// Concurrency
export const CONCURRENCY_PURCHASE = 'concurrency/purchase/';
export const CONCURRENCY_ASSIGN = 'concurrency/assign/';

// Assembly Attribute Feature
export const ADD_ATTRIBUTE_ENDPOINT = 'construction/requests/%s/assembly_material/';
export const EDIT_ATTRIBUTE_ENDPOINT = 'construction/requests/%s/assembly_material/';
export const DELETE_ATTRIBUTE_ENDPOINT = 'construction/requests/%s/assembly_material/';
// Manual Measurement
export const MANUAL_MEASUREMENT_FEATURES = 'requests/%s/manual_measurement_features/';

// Categories
export const CATEGORY_LIST = 'construction/requests/requirement_categories/';
// latest takeoffs
export const LATEST_TAKEOFFS = 'construction/requests/latest_requirements/';
// tagType
// construction/requests/d099d227-8453-405f-8070-1af419b002a8/tag_types/
export const CREATE_REQUEST_TAG_TYPE = 'construction/requests/%s/tag_types/';
export const CREATE_TAG_TYPE = 'construction/tags_info/';
export const UPDATE_TAG_TYPE = 'construction/tags_info/%s/';
export const UPDATE_REQUEST_TAG_TYPE = 'construction/requests/%s/tag_types/';

// tags
export const CREATE_REQUEST_TAG = 'construction/requests/%s/tags/';
export const CREATE_TAG = 'construction/tags_info/%s/tags/';
export const UPDATE_TAG = 'construction/tags_info/%s/tags/';
export const UPDATE_REQUEST_TAG = 'construction/requests/%s/tags/';
// subtags
export const CREATE_REQUEST_SUBTAG = 'construction/requests/%s/sub_tags/';
export const CRAETE_SUB_TAG = 'construction/tags_info/%s/subtags/';
export const UPDATE_SUB_TAG = 'construction/tags_info/%s/subtags/';
export const UPDATE_REQUEST_SUBTAG = 'construction/requests/%s/sub_tags/';

// getTags
export const FETCH_TAG_DATA = 'construction/tags_info/tag_attributes/';
export const SHARED_VIEW_FETCH_TAG_DATA = 'construction_shared_request/%s/tag_attributes/';

// export const FETCH_GLOBAL_TAG_DATA = 'construction/fx/tag_library/';
export const FETCH_GLOBAL_TAG_DATA = 'construction/tags_info/global_tags/';

// export const SHARED_VIEW_FETCH_GLOBAL_TAG_DATA = 'construction/fx/tag_library/';
export const SHARED_VIEW_FETCH_GLOBAL_TAG_DATA = 'construction_shared_request/global_tags/';

// Terms of Use
export const TERMS_OF_USE = 'https://attentiveinc-cs.notion.site/Terms-of-use-50362294598448ea9e5dd2ff50e85786';
// Privacy Policy
export const PRIVACY_POLICY = 'https://attentiveinc-cs.notion.site/Privacy-Policy-0c00e8f4fe8d4ab88d666c906f402045';
// Attentive AI
export const ATTENTIVE_AI = 'https://attentive.ai';
export const BEAM_AI = 'https://www.ibeam.ai';

// Folders
export const FOLDERS = 'folders/%s/';
export const CREATE_FOLDER = 'folders/';
export const MOVE_TO_FOLDER = 'folders/attach_requests/';
export const FOLDER_ACCESS = 'request/%s/folder_access';
export const FOLDER_APPROVE = 'folders/%s/approve_folder/';
// Construction Folders
export const FOLDERS_BPT = 'construction/folders/%s/';
export const CREATE_FOLDER_BPT = 'construction/folders/';
export const MOVE_TO_FOLDER_BPT = 'construction/folders/attach_requests/';

// Label boxes
export const LABEL_BOXES = 'requests/%s/edit_label_box/';

//Bid Management
export const BID_FOLDER = 'folders/%s/';
export const BID_REQUEST = 'requests/%s/';
export const BID_CONSTRUCTION_REQUEST = 'construction/requests/%s/';
export const BID_CONSTRUCTION_FOLDER = 'construction/folders/%s/';
export const CUSTOM_BID = 'bids/%s/';

// Firebase authentication
export const GENERATE_FIREBASE_TOKEN = 'users/%s/notification_token/';

// Beam GPT
export const BEAM_GPT = 'construction/requests/%s/gpt_session/';
export const PARSE_GPT = 'construction/requests/%s/parse_gpt/';
export const GPT_FEEDBACK = 'construction/requests/%s/gpt_feedback/';
export const END_SESSION = 'construction/requests/%s/end_session';
